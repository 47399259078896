import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  /**
   * The unique identifier of your packaged app.
   *
   * This is also known as the Bundle ID in iOS and the Application ID in
   * Android. It must be in reverse domain name notation, generally
   * representing a domain name that you or your company owns.
   *
   */
  appId: 'com.familytricks.app',
  /**
   * The human-friendly name of your app.
   *
   * This should be what you'd see in the App Store, but can be changed after
   * within each native platform after it is generated.
   *
   */
  appName: 'FamilyTricks',
  /**
   * The directory of your compiled web assets.
   *
   * This directory should contain the final `index.html` of your app.
   *
   */
  webDir: 'dist/daitask-pwa/browser',
  'plugins': {
    PushNotifications: {
      presentationOptions: ["alert", "badge", "sound"]
    }
  },
  ios: {
    handleApplicationNotifications: false,
    contentInset: 'never'
  }
};

export default config;
