import { Injectable} from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { HttpErrorService } from '../error/http-error.service';
import { HttpClient } from '@angular/common/http';
import { FamilyTricksSubscription } from './family-tricks-subscription.data';
import { environment } from 'src/environments/environment';
import { NewSubscription } from './new-subscription.data';
import { UpdatedSubscription } from './updated-subscription.data';
import { Purchase } from './purchase.model';
import { Transaction } from './transaction.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscriptionUrl = `${environment.apiUri}/v1.0/familysubscriptions`;

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService
  ) { }

  isActiveSubscription = toSignal(this.getIsActiveSubscription(), { initialValue: false});
  private getIsActiveSubscription() {
    return this.getSubscription().pipe(
      map((subscription) => {
        if (subscription && subscription.activeSubscription) {
          return true;
        }
        return false;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  getSubscription(): Observable<FamilyTricksSubscription> {
    return this.http.get<FamilyTricksSubscription>(this.subscriptionUrl);
  }

  addSubscription(newSubscription: NewSubscription): Observable<FamilyTricksSubscription> {
    return this.http.post<FamilyTricksSubscription>(this.subscriptionUrl, newSubscription).pipe(
      catchError(this.httpErrorService.handleError)
    );
  }

  updateSubscription(subscription: FamilyTricksSubscription): Observable<FamilyTricksSubscription> {
    const updatedSubscription: UpdatedSubscription = {
      receiveNewsletter: subscription.receiveNewsletter
    };

    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/receivenewsletter`, updatedSubscription).pipe(
      catchError(this.httpErrorService.handleError)
    );
  }

  acknowledgePurchaseOnBackend(purchase: Purchase): Observable<boolean> {
    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/acknowledgepurchase`, purchase).pipe(
      switchMap((subscription) => {
        if (subscription && subscription.activeSubscription) {
          return of(true);
        }
        return of(false);
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  verifyIosTransaction(transaction: Transaction): Observable<boolean> {
    return this.http.put<FamilyTricksSubscription>(`${this.subscriptionUrl}/verifytransaction`, transaction).pipe(
      switchMap((subscription) => {
        if (subscription && subscription.activeSubscription) {
          return of(true);
        }
        return of(false);
      }),
      catchError(this.httpErrorService.handleError)
    );
  }
}
