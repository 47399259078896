import { Capacitor } from '@capacitor/core';
import config from '../../capacitor.config';
import { environment } from 'src/environments/environment';

const auth0Domain = 'auth.family-tricks.com';
export const domain = `https://${auth0Domain}`;
export const clientId = 'b7BFM87yE7z3erauF0deYokTKzcWPOgc';
const { appId } = config;

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const web = Capacitor.getPlatform() === 'web';

export const callbackUri = web
  ? environment.callbackUriWeb
  : `${appId}://${auth0Domain}/capacitor/${appId}/callback`;
