import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {Browser} from '@capacitor/browser';
import {Preferences} from '@capacitor/preferences';
import { callbackUri } from 'src/app/auth.config';
import { ProfileService } from '../profile/profile.service';
import { Router } from '@angular/router';
import { LoginPageComponent } from '../../login-page/login-page.component';
import { MatDialog } from '@angular/material/dialog';

const returnTo = callbackUri;

@Injectable({providedIn: 'root'})
export class LogoutService {

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  logout() {
    this.profileService.logoutOfOneSignal();
    
    // clear profie and other app settings
    Preferences.clear().then(value => {

      // logout of auth0
      this.authService.logout({
        logoutParams: {
          returnTo,
        },
        async openUrl(url: string) {
          await Browser.open({ url });
        } 
      }).subscribe({
        next: () => {
          this.router.navigateByUrl(`login`);
          // this.dialog.open(LoginPageComponent, {
          //   panelClass: 'app-full-bleed-dialog',
          //   height: '100%',
          //   width: '100%',
          //   maxWidth: '480px',
          //   autoFocus: false,
          // });
        }
      });
    });
  }
}
